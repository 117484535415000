import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Title from "../components/Title/Title"

export default function Privacy() {
    return (
        <Layout>
            <Helmet>

            </Helmet>
            <Title>
                <h1>Privacy Policy</h1>
            </Title>
            <section style={{backgroundColor: "var(--neutral-50)"}}>
                <div style={{width: "min(90%, 1000px", margin: "auto"}}>
                    <h2>Introduction</h2>
                    <p>Your privacy is very important to me and you can be confident that your personal information will be kept safe and secure and will only be used for the purpose it was given to me. I adhere to current data protection legislation, including the General Data Protection Regulation (EU/2016/679) (the GDPR), the Data Protection Act 2018 and the Privacy and Electronic Communications (EC Directive) Regulations 2003.</p>
                    <p>This privacy notice tells you what I will do with your personal information from initial point of contact through to after your therapy has ended, including:</p>
                    <ul>
                        <li>Why I am able to process your information and what purpose I am processing it for</li>
                        <li>Whether you have to provide it to me</li>
                        <li>How long I will store it for</li>
                        <li>Whether there are other recipients of your personal information</li>
                        <li>Whether I intend to transfer it to another country</li>
                        <li>Whether I do automated decision-making or profiling</li>
                        <li>Your data protection rights</li>
                    </ul>
                    <p>I am happy to chat through any questions you might have about my data protection policy and you can contact me via email or telephone.</p>
                    <p>‘Data controller’ is the term used to describe the person or organisation that collects and stores and has responsibility for people’s personal data. In this instance, the data controller is me, Paula Abbey.</p>
                    <p>I am registered with the Information Commissioner’s Office, registration number ZA933895.</p>
                    <p>My phone number: <a href="tel:07898437229">07898 437229</a></p>
                    <p>My email address: <a href="mailto:hello@paulaabbey.co.uk">hello@paulaabbey.co.uk</a></p>
                    <h2>My lawful basis for holding and using your personal information</h2>
                    <p>The GDPR states that I must have a lawful basis for processing your personal data. There are different lawful bases depending on the stage at which I am processing your data. I have explained these below:</p>
                    <p>If you have had therapy with me and it has now ended, I will use legitimate interest as my lawful basis for holding and using your personal information.</p>
                    <p>If you are currently having therapy or if you are in contact with me to consider therapy, I will process your personal data where it is necessary for the performance of our contract.</p>
                    <p>The GDPR also makes sure that I look after any sensitive personal information that you may disclose to me appropriately. This type of information is called ‘special category personal information’. The lawful basis for me processing any special categories of personal information is that it is for provision of health treatment (in this case counselling) and necessary for a contract with a health professional (in this case, a contract between me and you).</p>
                    <h2>How I use your information</h2>
                    <h3>Initial contact</h3>
                    <p>When you contact me with an enquiry about my counselling services, I will collect information to help me satisfy your enquiry. This will include your name, your telephone number, or your email address, whichever you have provided for me. Alternatively, your GP or other health professional may send me your details when making a referral or a parent or trusted individual may give me your details when making an enquiry on your behalf.</p>
                    <p>If you decide not to proceed, I will ensure all your personal data is deleted within 28 days. If you would like me to delete this information sooner, just let me know.</p>
                    <h3>While you are accessing counselling</h3>
                    <p>Rest assured that everything you discuss with me is confidential. That confidentiality will only be broken if you disclose your involvement in money laundering, drug trafficking, terrorism related activity or I think you are at an extreme risk of harm to yourself or to someone else. I will always try to speak to you about this first, unless there are safeguarding issues that prevent this.</p>
                    <p>I will keep a record of your personal details to help the counselling services run smoothly. These details are kept securely in a locked filing cabinet in my private office and are not shared with any third party.</p>
                    <p>I will keep written notes of each session, these are kept on a password protected hard drive and anonymised for extra security.</p>
                    <p>For security reasons I do not retain text messages for more than 28 days. If there is relevant information contained in a text message, I will record it in a separate document on a password protected hard drive. Likewise, any email correspondence will be deleted after 28 days if it is not important. If necessary, I will record any relevant information you provided in a separate document on a password protected hard drive.</p>
                    <h3>After counselling has ended</h3>
                    <p>Once counselling has ended your records will be kept for seven years from the end of our contact with each other and are then securely destroyed. If you want me to delete your information sooner than this, please tell me.  However, in the case of counselling notes, my insurance company and my ethical body ask for records to be available for the period of seven years as outlined above.</p>
                    <h2>Third party recipients of personal data</h2>
                    <p>It is very unlikely that I will ever have to share your data. I will never sell it on or use it for unethical reasons. I may have to share it if my notes are subpoenaed by a court of law. If either you, or anyone you tell me about, is at an extreme risk of harm to self or others, I may have to pass this information on. I may also inform the relevant authorities if you disclose any information regarding terrorism, drug trafficking, safeguarding issues or money laundering.</p>
                    <p>When you make a payment of a counselling session, your payment will appear on bank records. These records may be seen by a third party such as an accountant or HMRC. These third parties will be bound by confidentiality, however, if you would prefer a more anonymous way of paying, please discuss with me at our first assessment session.</p>
                    <p>I may contract third parties such as scheduling services to carry out a specific task. In such cases I have carefully selected which partners I work with. I take great care to ensure that I have a contract with the third party that states what they are allowed to do with the data you share with them to access my service. I ensure that they do not use your information in any way other than the task for which they have been contracted.</p>
                    <h2>Your rights</h2>
                    <p>I try to be as open as I can be in terms of giving people access to their personal information. You have a right to ask me to delete your personal information, to limit how I use your personal information, or to stop processing your personal information. You also have a right to ask for a copy of any information that I hold about you and to object to the use of your personal data in some circumstances. You can read more about your rights at <a href="https://ico.org.uk/your-data-matters" target="_blank" rel="noopener noreferrer">ico.org.uk/your-data-matters</a>.</p>
                    <p>If I do hold information about you, I will:</p>
                    <ul>
                        <li>give you a description of it and where it came from.</li>
                        <li>tell you why I am holding it, tell you how long I will store your data and how I made this decision.</li>
                        <li>tell you who it could be disclosed to.</li>
                        <li>let you have a copy of the information in an intelligible form.</li>
                    </ul>
                    <p>You can also ask me at any time to correct any mistakes there may be in the personal information I hold about you.</p>
                    <p>To make a request for any personal information I may hold about you, please put the request in writing addressing it to <a href="mailto:hello@paulaabbey.co.uk">hello@paulaabbey.co.uk</a>.</p>
                    <p>If you have any complaints about how I handle your personal data please do data, hesitate to get in touch with me by writing or emailing to the contact details given above. I would welcome any suggestions for improving my data protection procedures.</p>
                    <p>If you want to make a formal complaint about the way I have processed your personal information you can contact the ICO which is the statutory body that oversees data protection law in the UK. For more information go to <a href="https://ico.org.uk/make-a-complaint" target="_blank" rel="noreferrer noopener">ico.org.uk/make-a-complaint</a>.</p>
                    <h2>Data security</h2>
                    <p>I take the security of the data I hold about you very seriously and as such I take every effort to make sure it is kept secure. I anonymise the notes I take about our work together and keep these separate from your name and contact details. I keep these notes on a password protected, encrypted hard drive which is stored in a locked cabinet in my office. I keep your contact details form as a copy in a locked filing cabinet in my private office.</p>
                    <h3>Visitors to my website</h3>
                    <p>When someone visits my website, I use a third-party service, Netlify, to collect standard internet log information and Google Analytics to collect details of visitor behaviour patterns. I do this to find out things such as the number of visitors to the various parts of the site. This information is only processed in a way that does not identify anyone. I do not make, and do not allow Netlify or Google Analytics to make, any attempt to find out the identities of those visiting my website.</p>
                    <p>I use legitimate interests as my lawful basis for holding and using your personal information in this way when you visit my website.</p>
                    <p>I use Google Analytics so that I can continually improve my service to you. You can read Google's privacy policy <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">here</a>.</p>
                    <p>No user-specific data is collected by me or any third party. If you fill in a form on my website, that data will be temporarily stored on the web host before being sent to me.</p>
                </div>
            </section>
        </Layout>
    )
}